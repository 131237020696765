import http, {makeSearchParams} from 'utils/http';

export const getSubscriptionPlans = () => {
  return http('GET', `/g/subscription-plans`);
};

export function postSubscribe(formData) {
  return http('POST', '/subscribe', formData);
}

export function postSubscribeTrial(formData) {
  return http('POST', '/subscribe/trial', formData);
}

export function postSubscribeTrialToBasic(formData) {
  return http('POST', '/subscribe/trial/basic', formData);
}

// GET subscription plan
export function getSubscription() {
  return http('GET', 'subscription');
}

export function deleteSubscription() {
  return http('DELETE', 'subscription');
}

export function getSubscriptionPaymentMethod() {
  return http('GET', 'subscription/payment-method');
}

export function putSubscriptionPaymentMethod(formData) {
  return http('PUT', 'subscription/payment-method', formData);
}

export const getAccountSubscription = (queries) => {
  return http('GET', '/account/subscription', makeSearchParams(queries));
};
